<template>
  <div>
    <!--begin::Detail-->
    <b-card header-tag="header" footer-tag="footer" class="gutter-b">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex align-items-center mb-4">
            <span class="font-weight-bold mr-4">Name :</span>
            <span>{{ role.name }}</span>
          </div>
          <div class="d-flex align-items-center mb-4">
            <span class="font-weight-bold mr-4">Permission :</span>
          </div>
          <div class="d-flex flex-column w-100 flex-wrap" style="gap: 8px">
            <div v-for="(permission, index) in role.permissions" :key="index">
              {{ index + 1 }}. {{ permission.name }}
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <b-button variant="primary" @click="$router.go(-1)">Back</b-button>
    <!--end::Detail-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { formatDate } from "@/utils/helper";

export default {
  name: "DetailRole",
  data() {
    return {
      role: {},
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Role", route: "/role" },
      { title: "View" },
    ]);
  },
  created() {
    this.initData();
  },
  methods: {
    formatDate,
    initData() {
      try {
        this.$axios
          .get(`${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/role`)
          .then(({ data }) => {
            this.role = data;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
};
</script>
<style lang="scss"></style>
